<template>
  <b-row>
    <!-- Select Coach -->
    <b-col>
      <basic-select
        :value.sync="selectedCoach"
        :options="coaches"
        :solid="false"
        :loading="loading"
        label="Coach"
        placeholder="--- Chọn coach ---"
        changeValueByObject
        @update:value="onSelectCoach"
      />
    </b-col>

    <!-- Select Group -->
    <b-col>
      <basic-select
        :value.sync="selectedGroup"
        :options="groups"
        :solid="false"
        :loading="loading"
        label="Tên nhóm"
        placeholder="--- Chọn nhóm ---"
        changeValueByObject
        @update:value="onSelectGroup"
      />
    </b-col>

    <b-col class="align-self-center">
      <div
        v-if="selectedGroup"
        class="d-flex align-items-center justify-content-center"
      >
        <avatar
          size="40px"
          :text="selectedGroup.name"
          :src="get(selectedGroup, 'image.url')"
          :rounded="true"
        ></avatar>
        <div class="d-flex flex-column ml-5">
          <h6 style="color: #515356" class="mb-0">
            {{ selectedGroup.name }}
          </h6>
          <span>{{ selectedGroup.healthCoachName }}</span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { get } from 'lodash';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('groupDashboard');
export default {
  name: 'FiltersTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedCoach: null,
      selectedGroup: null,
    };
  },

  computed: {
    ...mapState(['coaches', 'groups']),

    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    currentUser: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },

  mounted() {
    this.initData();
  },

  methods: {
    get,

    initData() {
      this.selectedCoach = {
        id: this.currentUser.id,
        name: this.currentUser.fullName,
      };
    },

    onSelectCoach(value) {
      this.$emit('select:coach', value);

      this.selectedGroup = null;
      this.onSelectGroup(this.selectedGroup);
    },

    onSelectGroup(value) {
      this.$emit('select:group', value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
